<template>
  <li class="scored-asset" :class="{'completed': quiz.is_complete}">
    <i class="k-asset-icon" :class="getAssetIcon('quiz')"></i>
    <div class="asset-details">
      <router-link :to="{ name: 'module_quiz', params: { moduleId: moduleId, moduleQuizId: quiz.id }}" class="router-link">
        {{ quiz.name }}
      </router-link>
      <span v-if="quiz.deadline" class="asset-due-date">
        Due: <b>{{ parseTimestamp(quiz.deadline, undefined, "do MMM yyyy") }}</b>
      </span>
      <k-asset-tags :asset="quiz"></k-asset-tags>
    </div>
    <i v-if="quiz.is_complete" class="fas fa-check"></i>
    <div class="overall-score">
      <k-progress shape="circle" size="xsmall" :percentage="progressPercent" ariaLabel="Quiz Score"></k-progress>
    </div>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_quiz', params: { moduleId: moduleId, moduleQuizId: quiz.id }}">
      View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
import KProgress from '../components/k-progress.vue';
import KAssetTags from '../components/k-asset-tags.vue';
import TimeMixin from '../mixins/time-mixins';
import AssetIconMixin from '../mixins/asset-icon-mixin';

export default {
  mixins: [TimeMixin, AssetIconMixin],

  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
  },

  components: {
    KProgress,
    KAssetTags,
  },

  computed: {
    progressPercent() {
      return this.quiz.latest_score || 0;
    },
  },
};
</script>
