<template>
  <div v-if="extendedProps" class="k-event" :class="extendedProps.is_dot_event ? 'dot-event' : ''">
    <span>
      <i v-if="extendedProps.is_dot_event && !isModuleEvent" class="fas fa-circle"></i>
      <i v-if="extendedProps.is_dot_event && isModuleEvent" class="fa-duotone fa-hourglass-half"></i>
      <span class="dot-event-time" v-if="extendedProps.is_dot_event">{{ arg.timeText }}</span>
      <b>{{ arg.event.title }}</b>
    </span>
  </div>
</template>

<style scoped>
.dot-event {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  overflow: hidden;
}

.dot-event i.fas.fa-circle {
  font-size: 10px;
}

.dot-event .dot-event-time {
  color: var(--kate-type-light);
}

</style>

<script>

export default {
  props: {
    arg: Object,
  },

  computed: {
    extendedProps() {
      return this.arg?.event?.extendedProps;
    },

    isModuleEvent() {
      return this.arg?.event?.extendedProps.event_type === 'module_start' || this.arg?.event?.extendedProps.event_type === 'module_completion';
    },
  },
};
</script>
