<template>
  <div class="content-with-spacing help-page-container">
    <div class="heading-container">
      <div class="top-breadcrumb">
        <router-link :to="{ name: 'help' }">Help</router-link>
        <span class="breadcrumb-divider"
          ><i class="fas fa-angle-right"></i
        ></span>
        <a class="current-page">Advanced KATE Setup</a>
        <div class="heading">
          <h1>Advanced KATE Setup</h1>
        </div>
      </div>
    </div>

    <p>
      Some projects on KATE support a <strong>git</strong> mode. This will allow
      you to sync your code on your computer, work on it with your own tool and
      submit using git commands. For some projects, such as the advanced machine
      learning ones, it is required to use this mode so you can train your
      models locally and submit a trained version.
    </p>
    <h2>Prerequisites</h2>
    <p><img src="../../img/git_logo.png" alt="git_logo" height="60px" /></p>
    <p>
      Before getting started, make sure you have git installed on your computer
    </p>
    <p>
      <strong>Git</strong> is the <strong>version control system</strong> used
      by KATE and many other systems for
      <strong>software development and collaboration</strong>; installation is
      required in order to work on and submit assignments directly from your own
      computer, rather than via the Web IDE.
    </p>
    <p>
      On Linux and MacOS git is usually available by default. You can open a
      terminal an run <code>git --version</code> to check your installation.
    </p>
    <p>
      If you do not have git, you can
      <a target="_blank" href="https://git-scm.com/downloads">download</a> it
      for your operating system, then follow the installation instructions.
    </p>
    <p>
      On <strong>Windows</strong>, you will need to install
      <strong>Git Bash</strong>, which provides users with a
      <strong>command line shell</strong> providing the same commands as those
      used in the <strong>Terminal</strong> for
      <a
        target="_blank"
        href="https://www.lifewire.com/operating-systems-unix-vs-windows-2180225"
        >Unix-based operating systems</a
      >
      (such as <strong>Mac OS</strong> and <strong>Linux</strong>) to be run.
    </p>
    <p>
      It's useful to understand basic
      <a
        target="_blank"
        href="https://dev.to/unseenwizzard/learn-git-concepts-not-commands-4gjc"
        >Git concepts</a
      >
      - but if you find the command line interface challenging, we explain below
      how to use git with VSCode.
    </p>

    <h2>Getting started with KATE and git</h2>
    <p>
      First, pick an assignment that supports the git mode. Those have an
      additional <strong>Clone</strong> button displayed at the top.
    </p>
    <ul>
      <li>
        <p>
          Click on <strong>Clone</strong> and choose one of the two options:
        </p>
        <ul>
          <li><code>HTTPS</code> (recommended): does not require any setup</li>
          <li>
            <code>SSH</code>: requires you to set your SSH keys on Gitlab first.
            First you will need to create a new key on your machine (on Linux
            and MacOS you can use <code>ssh keygen</code>, on Windows you will
            need <code>PuTTYgen</code>, you can find resources online for your
            OS). Once you have created keys, go to
            <router-link :to="{ name: 'profile_page' }">My Profile</router-link>
            and copy-paste your key with a name of you choice.
            <p>
              Advanced users can also
              <router-link :to="{ name: 'help_kate_fingerprints' }"
                >check SSH fingerprint</router-link
              >.
            </p>
          </li>
        </ul>
      </li>
      <li><p>Copy the URL for either HTTPS or SSH.</p></li>
    </ul>
    <p>
      You now have the choice to use either a command line interface or VSCode
      to setup the repository locally using the URL copied above.
    </p>

    <h2>Using git with VSCode</h2>

    <p>
      Open VS Code and navigate to <strong>View > Command Palette</strong> then
      type and select <strong>Git: Clone</strong>.
    </p>
    <p>
      When prompted to <strong>enter a repository URL</strong>, paste the one
      copied from KATE, and choose a suitable location on your computer to store
      the folder.
    </p>
    <p><img src="../../img/vscode_git_url.png" alt="vscode_git_url" /></p>
    <p>
      Once cloned, <strong>open the folder in VS Code</strong> either via
      <strong>Open Repository</strong> on the pop-up or via
      <strong>File > Open</strong> (selecting the whole folder rather than a
      specific file).
    </p>
    <p>You should then see the contents of the folder on the left.</p>
    <p>
      The <strong>.gitignore</strong> file tells git of any files that do not
      need to be tracked, and can be left unedited.
    </p>
    <p>
      The <strong>.gitlab-ci.yml</strong> file is required by KATE and shouldn't
      be edited or deleted.
    </p>
    <p>
      Selecting a file will open it in the <strong>Editor</strong>. The code for
      <strong>Python (.py) files</strong> should be displayed with appropriate
      <strong>syntax highlighting</strong>.
    </p>
    <p><img src="../../img/vscode_explorer.png" alt="vscode_explorer" /></p>
    <p>
      <img
        src="../../img/vscode_branch_python.png"
        alt="vscode_branch_python"
      />
    </p>

    <h3>Making a submission to KATE</h3>
    <p>
      Once you're ready to make a submission, click on the
      <strong>Git</strong> icon to the top left.
    </p>
    <p><img src="../../img/vscode_git_commit.png" alt="vscode_git_commit" /></p>
    <p>
      This will show a list of the <strong>Changes</strong>, highlighting the
      number of code issues (if any) and if a file has been modified. Hovering
      on a file provides options to <strong>Open File</strong>,
      <strong>Discard Changes</strong>, or <strong>Stage Changes</strong>.
    </p>
    <p>
      If we only want to submit some of the files to KATE, we can click
      <strong>Stage Changes</strong> on only those files. If we want to to
      submit all of them, this isn't necessary (if no files are staged, VS Code
      will stage all files automatically as part of the following step).
    </p>
    <p>
      Next, <strong>VS Code requires a commit message</strong> to be entered.
      These messages are more useful when working on more complex or
      collaborative projects; for now, anything brief is fine. Click the
      <strong>tick</strong> icon to
      <strong>commit the code to your local master branch</strong>.
    </p>
    <p>
      Finally, we need to <strong>push the updated local branch</strong> to the
      <strong>remote repository</strong>. To do so, click on the up and down
      arrow icon pair to the right of the branch name to
      <strong>synchronize changes</strong>.
    </p>
    <p><img src="../../img/vscode_push.png" alt="vscode_push" /></p>
    <p>
      <a href="https://edukate.ai/" target="_blank">KATE</a> will now
      <strong>automatically generate feedback</strong> for your code. This can
      be found on the <strong>Feedback page</strong> for the given project or
      assignment.
    </p>

    <h2>Using Git With Command Line</h2>

    <p>
      On your computer, start a new terminal, move to where you want to work and
      run:
    </p>
    <pre><code>git clone &lt;url_you_copied&gt;
    </code></pre>
    <p>
      The URL will start with <code>https</code> if you use HTTPS, or
      <code>git@</code> if you use <code>SSH</code>. This will download the
      repository on your computer and automatically link it to your KATE
      account.
    </p>
    <p>If you don't know how to start a terminal on your OS, check:</p>
    <ul>
      <li>
        <a
          target="_blank"
          href="https://www.howtogeek.com/249966/how-to-install-and-use-the-linux-bash-shell-on-windows-10/"
          >On Windows</a
        >
      </li>
      <li>
        <a
          target="_blank"
          href="https://askubuntu.com/questions/183775/how-do-i-open-a-terminal"
          >On Ubuntu</a
        >
      </li>
      <li>
        <a
          target="_blank"
          href="http://blog.teamtreehouse.com/introduction-to-the-mac-os-x-command-line"
          >On MacOS</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Use regular <code>git</code> commands to check your changes and submit
        them. You can stick to the <code>master</code> branch only.
        <ul>
          <li>
            If you haven't used <code>git</code> before, below are the main
            commands useful to work with <code>KATE</code>:
            <ul>
              <li>
                <code>git status</code>: See the list of all files that you've
                modified
              </li>
              <li>
                <code>git diff</code>: Review the changes you've made on all
                files. If you want to see only one file, add the name of the
                file as argument.
              </li>
              <li>
                <code>git add &lt;name_of_file.py&gt;</code>: Add a file you've
                modified to your submission
              </li>
              <li>
                <code>git commit -m "your message here"</code>: Create a new
                commit/submission with all the files you've added
              </li>
              <li>
                <code>git push</code>: Push your commit/submission to KATE
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>
      You can submit as many times as you want. You will get a new feedback from
      KATE every time you push.
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$logger.pageReady();
    this.$Loading.finish();
  },
};
</script>
