<template>
  <div>
    <div id="presentation">
      <div class="reveal" ref="presentation">
        <div class="slides">
          <section class="markdown" :data-background-color="this.themeStore.theme !== 'light' ? '#191919' : '#ffffff'" data-separator="\{::\s*next\s*\}" data-markdown>
            <template data-template v-text="textAreaContent"></template>
          </section>
        </div>
      </div>
    </div>
    <div id="shortcut-panel">
      <p><strong>Keyboard Shortcuts:</strong></p>
      <ul>
        <li><kbd class="shortcut-key">F</kbd> Enable Full Screen</li>
        <li><kbd class="shortcut-key">O</kbd> Show Slides Overview</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.reveal {
  width: 100%;
  height: 550px;
  font-size: 25px;
}

.reveal .slides {
  padding-top: 35px;
  padding-bottom: 20px;
  text-align: left;
  width: 90%;
}

.scrollable-slide {
  height: 550px;
  overflow-y: auto !important;
  padding-right: 15px;
  padding-top: 30px;
}

#shortcut-panel {
  margin-top: 20px;
  font-size: 18px;
}

.shortcut-key {
  padding: 3px 6px;
  background-color: var(--kate-mono-0);
  border: var(--border-primary);
  border-radius: 3px;
  color: var(--kate-type-dark);
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 2px;
  min-width: 24px;
  text-align: center;
}
</style>

<style>
.reveal .code-wrapper {
  font-size: 20px;
}

.reveal pre {
  width: 100%;
}
</style>

<script>
import Reveal from 'reveal.js';
// eslint-disable-next-line import/extensions
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';
// eslint-disable-next-line import/extensions
import Highlight from 'reveal.js/plugin/highlight/highlight.esm.js';
import useThemeStore from '../stores/theme';
import { renderMarkdown } from '../modules/maths-markdown';

import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/black.css';
import 'reveal.js/plugin/highlight/monokai.css';

export default {
  name: 'KPresentation',
  props: {
    markdownContent: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      themeStore: useThemeStore(),
    };
  },

  computed: {
    textAreaContent() {
      return renderMarkdown(this.markdownContent);
    },
  },
  methods: {
    checkAndHandleSlideHeight(slide) {
      if (slide.clientHeight > 550) {
        slide.classList.add('scrollable-slide');
      }
    },
  },
  mounted() {
    Reveal(this.$refs.presentation).initialize({
      plugins: [
        Markdown,
        Highlight,
      ],
      center: true,
      margin: 0.01,
      embedded: true,
      markdown: {
        smartypants: true,
      },
    });

    Reveal(this.$refs.presentation).on('ready', event => {
      this.checkAndHandleSlideHeight(event.currentSlide);
    });

    Reveal(this.$refs.presentation).on('slidechanged', event => {
      this.checkAndHandleSlideHeight(event.currentSlide);
    });
  },
};
</script>
