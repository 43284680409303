<template>
  <div v-if="asset" class="completion-buttons">
    <k-tooltip v-if="!moduleAssetCompleted" class="bottom"
      text="Click on this button to mark this page as complete."
    >
      <button class="mark-complete-button btn btn-outlined" title="Mark as complete" aria-label="Mark as complete" @click="handleCompletion">
        <i class="fas fa-check"></i> Mark as Complete
      </button>
    </k-tooltip>
    <k-tooltip v-else class="bottom"
      text="Click on this button to mark this page as incomplete."
    >
    <button class="mark-incomplete-button btn btn-outlined" title="Mark as incomplete" aria-label="Mark as incomplete" @click="handleCompletion">
      <i class="fas fa-check"></i> Completed
    </button>
  </k-tooltip>
</div>
</template>

<style scoped>
.completion-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.mark-complete-button {
  border-color: var(--kate-type-success);
  color: var(--kate-type-success);
  min-width: 170px;
  position: relative;
}

.mark-incomplete-button {
  background-color: var(--kate-success);
  border-color: var(--kate-success);
  color: var(--kate-type-dark);
  min-width: 170px;
  position: relative;
}

.mark-incomplete-button:hover,
.mark-complete-button:hover,
.mark-incomplete-button:active,
.mark-complete-button:active {
  background-color: var(--kate-success-alt);
  border-color: var(--kate-success-alt);
  color: var(--kate-type-dark);
}

.mark-complete-button:disabled,
.mark-complete-button:hover:disabled {
  border-color: var(--kate-disabled);
  color: var(--kate-disabled);
  background-color: transparent;
}

.mark-complete-button i,
.mark-incomplete-button i {
  margin-right: 10px;
}
</style>

<script>
import KTooltip from '../components/k-tooltip.vue';
import ErrorMixin from '../mixins/error-mixins';

export default {
  components: {
    KTooltip,
  },

  mixins: [ErrorMixin],

  props: {
    asset: {
      type: Object,
    },
    completionPayload: {
      type: Object,
    },
    completionHandler: {
      type: Function,
    },
  },

  computed: {
    assetType() {
      return this.asset?.asset_type;
    },
    moduleAssetId() {
      return this.asset?.id;
    },
    moduleAssetCompleted() {
      return Boolean(this.asset?.is_complete);
    },
    completionLogPayload() {
      return { asset_type: this.assetType, module_asset_id: this.moduleAssetId };
    },
  },

  methods: {
    markComplete() {
      this.$logger.info(`Marking ${this.assetType} as complete`, this.completionLogPayload);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/${this.assetType}/${this.moduleAssetId}/complete`, this.completionPayload || {})
        .then(res => {
          this.$logger.info(`Marked ${this.assetType} as complete`, this.completionLogPayload);
          this.$sidebar.updateCompletionStatus(this.assetType, this.moduleAssetId, true);
          this.$ktoast.showBadgesAchieved(res.data.badges_achieved);
        }).catch(err => {
          this.$logger.autowarn(`Error marking ${this.assetType} as complete`, this.completionLogPayload, err);
          this.showError(err, true);
        }).then(() => {
          this.$Loading.finish();
        });
    },
    markIncomplete() {
      this.$logger.info(`Marking ${this.assetType} as incomplete`, this.completionLogPayload);
      this.$http.put(`/api/curriculum/${this.assetType}/${this.moduleAssetId}/incomplete`)
        .then(() => {
          this.$logger.info(`Marked ${this.assetType} as incomplete`, this.completionLogPayload);
          this.$sidebar.updateCompletionStatus(this.assetType, this.moduleAssetId, false);
        }).catch(err => {
          this.$logger.autowarn(`Error marking ${this.assetType} as incomplete`, this.completionLogPayload, err);
          this.showError(err, true);
        });
    },
    toggleCompletion() {
      if (this.moduleAssetCompleted) {
        this.markIncomplete();
      } else {
        this.markComplete();
      }
    },
    handleCompletion() {
      if (this.completionHandler) {
        this.completionHandler();
        return;
      }
      this.toggleCompletion();
    },
  },
};
</script>
