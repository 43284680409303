<template>
  <div class="instructions-container row">
    <div class="feedback-instructions panel panel-default instructions-readme">
      <div v-if="projectStarted && projectMeta.resource_link" class="panel panel-default instruction-panel">
        <span v-if="projectMeta.kloud_enabled" class="kloud-enabled-instructions">
          <span>Open content by clicking on the <b>Open Learning Environment</b> above.</span>
          <div>After completing some or all of the exercises, you can submit your assignment via the learning environment to receive
            <router-link :to="{ name: 'pak_ov_feedback'}">feedback in KATE</router-link>.
          </div>
        </span>
        <span v-else class="kloud-disabled-instructions">
          First, please <a :href="`/api/curriculum/pak/${projectMeta.id}/zip`"><b>download the resources</b></a> available.
          <div v-if="notebookOnly">
            <span>Once downloaded, you can open the notebooks in <b>Jupyter</b> and get started.</span><br/>
            <span>After completing some, or all of the exercises, you can submit it to receive <b>feedback</b> on your code.</span>
          </div>
        </span>
      </div>
      <div v-else-if="!projectStarted" class="panel panel-default instruction-panel">
        Click on the <b>"Start Assignment"</b> button on the top right of the page to get started.
      </div>

      <div v-if="instructions" v-html="$kpurify.sanitise(instructions)" class="panel-body">
      </div>
      <div v-else-if="competitionModeWithoutFork" class="panel-body general-instructions notebook">
        <p>This is a timed assignment. Once you start the assignment the timer will begin. Once the timer has ended, you will be unable to make any further submissions.
        </p>
      </div>
      <div v-else-if="notebookOnly" class="panel-body general-instructions notebook">
        <p>In the zip file downloaded from the <code>Resources</code> button, you will find your assignment notebook.<br />
          Once you have added your code, select the <code>Notebook</code> tab and submit your notebook.<br />
          From there, you will have to resubmit your notebook each time you make changes to it.<br/>
          In the event you lose the file, you may simply click the <code>download latest notebook</code> button in the <b>Notebook</b> tab. This contains the notebook with the latest submit you've made.<br />
          Once submitted, your feedback will be generated. Go to the <code>Feedback</code> tab to check it out!
        </p>
      </div>
      <div v-else class="panel-body general-instructions ide">
        <p>Once you have started the assignment, you will gain access to the <code>Web IDE</code> tab, where you can start coding straight away.<br />
          If you prefer to work on your own IDE, the <code>Clone</code> dropdown button allows you to clone the repository so you can work locally (check out the help page to know how to set it up).<br />
          Once you have added and reviewed your code, click the <code>Submit</code> button.<br />
          You will see a loading notification, indicating that KATE is evaluating your code and generating feedback.<br />
          Go to the <code>Feedback</code> tab to check it out!
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.instructions-container {
  display: flex;
  justify-content: center;
}

.feedback-instructions {
  padding-left: 8ch;
  padding-right: 8ch;
  margin: 0 15px;
}

.feedback-instructions.side-panel-container {
  background-color: transparent;
}

.feedback-instructions .panel-body {
  padding: 15px 0;
}

.instructions-readme {
  flex: 1;
}

.instructions-readme table {
  margin: 12px 0;
}

.instructions-readme table tr td,
.instructions-readme table th {
  padding: 8px 12px;
  border: 1px solid var(--kate-type-primary);
}

.instructions-readme table th {
  background-color: var(--kate-background-body);
  border-bottom: solid 2px var(--kate-type-primary);
  font-weight: bold;
  color: var(--kate-type-light);
}

.instructions-readme table tr:nth-child(2n) {
  background-color: var(--kate-panel-alt);
}

.instruction-panel {
  padding: 30px 15px;
  text-align: center;
  color: var(--kate-type-light);
  margin-top: 30px;
}

.instruction-panel span span {
  line-height: 1.5;
}

.instruction-panel span span b {
  color: var(--kate-secondary);
}

.instructions-readme h1 {
  margin: 0 !important;
  font-weight: bold;
}

.instructions-readme h2 {
  padding: 15px 0;
  margin: 0;
  color: var(--kate-secondary);
}

.instructions-readme h3 {
  padding: 10px 0;
  font-weight: bold;
  color: var(--kate-secondary);
}

.instructions-readme p {
  line-height: 1.5;
  padding: 0;
  color: var(--kate-type-light) !important;
}

.instructions-readme li {
  color: var(--kate-type-light) !important;
}

.general-instructions code {
  color: var(--kate-type-light);
  font-size: 0.9em;
  background-color: var(--kate-background);
}

@media (max-width: 1024px) {
  .instructions-container .panel {
    max-width: 100%;
    padding: 10px 30px;
  }
}
</style>

<script>

export default {
  props: {
    instructions: String,
    projectMeta: Object,
    projectDetails: Object,
  },
  mounted() {
    this.$logger.pageReady();
  },

  computed: {
    notebookOnly() {
      if (!this.projectMeta) {
        return false;
      }
      return Boolean(this.projectMeta.notebook_only);
    },
    projectStarted() {
      return Boolean(this.projectDetails && this.projectDetails.fork_date);
    },
    competitionModeWithoutFork() {
      if (this.projectDetails && this.projectDetails.time_limit && !this.projectDetails.fork_date) {
        return true;
      }
      return false;
    },
    encodedResourceLink() {
      if (this.projectMeta && this.projectMeta.resource_link) {
        return encodeURIComponent(this.projectMeta.resource_link);
      }
      return undefined;
    },
  },
};
</script>
