<template>
  <div id="markdown-page" v-if="ready">
    <div class="content-with-spacing">
      <div class="heading-container">
        <h1 v-if="markdownPageTitle">{{ markdownPageTitle }}</h1>
          <div class="heading-buttons">
            <k-tooltip v-if="presentationContent" class="bottom" text="Toggle between page mode and presentation mode.">
              <div class="toggle-mode-container">
                <k-toggle v-model="presentationModeEnabled" :round="true" size="small" label="Toggle Display Mode"></k-toggle>
              </div>
            </k-tooltip>
            <module-asset-completion-button :asset="asset"></module-asset-completion-button>
        </div>
      </div>
      <div v-if="markdownPageContentReady && !presentationModeEnabled"
        id="markdown-page-section"
        ref="pageModeContainer"
        v-html="markdownContentAsHtml"
        class="markdown-page-container e-learning panel"
      ></div>
      <div v-if="markdownPageContentReady && presentationModeEnabled" class="markdown-page-container panel">
        <KPresentation :markdownContent="presentationContent" />
      </div>
    </div>
  </div>
</template>

<style>
#markdown-page .content-with-spacing {
  max-width: 960px;
}

/* ||. font-size */
#markdown-page .heading-container ~ div,
#markdown-page h5 {
  font-size: clamp(18px, 2vw, 21px);
}

#markdown-page h4 {
  font-size: clamp(21px, 2vw, 23px);
}

#markdown-page h3 {
  font-size: clamp(23px, 2vw, 25px);
}
/* ||. */

.markdown-page-container img {
  margin: 0 auto;
  display: block;
  max-height: 600px;
  max-width: 70%;
  padding: 25px 0;
}

.markdown-page-container p {
  color: var(--kate-type-primary);
}

/* Need to use un-scoped CSS for it to apply to v-html content */
.markdown-page-container.e-learning > table {
  width: 100%;
  margin-bottom: 1em;
  border: 1px solid var(--kate-background-alt-alpha);
}

.markdown-page-container.e-learning thead tr {
  background-color: var(--kate-background-body);
  border-color: var(--kate-background-alt-alpha);
  color: var(--kate-type-light);
  font-weight: bold;
}

.markdown-page-container.e-learning > table > tbody > tr > td,
.markdown-page-container.e-learning > table > tbody > tr > th,
.markdown-page-container.e-learning > table > tfoot > tr > td,
.markdown-page-container.e-learning > table > tfoot > tr > th,
.markdown-page-container.e-learning > table > thead > tr > td,
.markdown-page-container.e-learning > table > thead > tr > th {
  padding: 0.5em;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid var(--kate-background-alt-alpha);
}

.markdown-page-container.e-learning tbody tr:nth-child(2n) {
  background-color: var(--kate-panel-alt);
}

.markdown-page-container.e-learning h1#markdown-page {
  padding-top: 0;
  margin-top: 0;
}

.markdown-page-container.e-learning > h3,
.markdown-page-container.e-learning > h4 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.hidden-outer {
  position: relative;
  padding: 20px;
  margin: 15px 0;
  border-radius: 4px;
  background-color: var(--input-background);
}

.hidden-outer .btn-toggle {
  position: absolute;
  right: 10px;
  top: 25px;
  background-color: transparent;
  color: var(--kate-primary);
  border: 1px solid var(--kate-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hidden-outer .btn-toggle:hover {
  background-color: var(--kate-primary);
  color: var(--kate-type-dark);
}

.hidden-outer .btn-toggle:active {
  background-color: var(--kate-primary);
  color: var(--kate-type-light);
}

.hidden-outer .hidden {
  display: none;
  padding: 15px;
  margin-top: 15px;
  background-color: var(--kate-mono-0);
  border-radius: 4px;
}

.hidden-outer:not(:has(h4)) .btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}

.default-hidden-title {
  font-style: italic;
}

.hidden-inner {
  margin-top: 25px;
}

.hidden-outer .hidden-inner.hidden + .btn-toggle {
  background-color: var(--kate-danger);
}

.markdown-page-container.panel ol li,
.markdown-page-container.panel ul li {
  color: var(--kate-type-primary);
}

/* === Notes === */
.markdown-page-container .kate-note {
  background-color: var(--kate-note);
  padding: 1.2em;
  margin: 0 15px 15px;
  border-radius: 4px;
  position: relative;
  box-shadow: var(--box-shadow);
}

.markdown-page-container .kate-note::before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: var(--kate-note);
  border-width: 10px;
  top: 10px;
}

/* === Print === */
@media print {
  header,
  aside,
  .heading-container,
  .buttons-container.sticky,
  .chat-box-container,
  .feedback-form-container,
  footer,
  nav {
    display: none !important;
  }

  #app,
  body {
    overflow: visible;
    visibility: hidden;
  }

  .sidebar-page-content.sidebar-open {
    padding-left: 0;
  }

  #markdown-page-section {
    display: block;
    position: relative;
    width: auto;
    height: auto;
    overflow: visible;
    visibility: visible;
    margin: 0;
    page-break-inside: always;
  }
}

</style>

<style scoped>
.heading-container {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.heading-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.toggle-mode-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.markdown-page-container {
  padding: 25px;
}

.markdown-page-container.panel.panel-default {
  display: flex;
  flex: 1;
}

.category-header {
  margin-bottom: 20px;
}

#content .heading-container h1 {
  margin: 0;
}

</style>

<script>
import { markdown } from '../modules/maths-markdown';
import KTooltip from '../components/k-tooltip.vue';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';
import KToggle from '../components/k-toggle.vue';
import KPresentation from '../components/k-presentation.vue';
import ModuleAssetCompletionButton from './module-asset-completion-button.vue';

export default {
  components: {
    KTooltip,
    KPresentation,
    KToggle,
    ModuleAssetCompletionButton,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    asset: {
      type: Object,
    },
    sidebarReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      presentationModeEnabled: false,
      markdownPageContentReady: false,
      pageContent: undefined,
      presentationContent: undefined,
      hiddenContentButtons: undefined,
      hiddenContentDivs: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getContent();
  },

  watch: {
    assetId() {
      this.getContent();
    },

    renderingInPageMode() {
      if (this.renderingInPageMode) {
        this.$nextTick(this.renderHiddenElementsInPageMode);
      }
    },
  },

  computed: {
    ready() {
      return Boolean(this.asset && this.markdownPageContentReady && this.sidebarReady);
    },

    assetId() {
      return this.asset?.id;
    },

    markdownPageTitle() {
      return this.asset?.name;
    },

    markdownContentAsHtml() {
      return this.markdownToHtml(this.pageContent);
    },

    renderingInPageMode() {
      return this.ready && !this.presentationModeEnabled;
    },
  },

  methods: {
    renderHiddenElementsInPageMode() {
      const container = this.$refs.pageModeContainer;
      if (!container) {
        this.$logger.warn('Could not find container to render hidden elements');
        return;
      }
      this.renderHiddenElements(this.$refs.pageModeContainer);
    },

    renderHiddenElements(container) {
      this.hiddenContentButtons = container.querySelectorAll('button');
      this.hiddenContentDivs = container.querySelectorAll('div');

      const divsById = {};
      this.hiddenContentDivs.forEach(div => {
        const divId = div.id;
        divsById[divId] = div;
      });

      this.hiddenContentButtons.forEach(button => {
        const buttonId = button.id.replace('button-', '');
        button.addEventListener('click', () => {
          const div = divsById[`hidden-${buttonId}`];
          if (div) {
            div.classList.toggle('hidden');
          }
        });
      });
    },

    markdownToHtml(snippet) {
      if (snippet) {
        return markdown(snippet);
      }
      return undefined;
    },

    getContent() {
      if (this.assetId) {
        this.$logger.info('Getting markdown page content', { moduleMarkdownPageId: this.assetId });
        this.$http.get(`/api/curriculum/markdown_page/${this.assetId}`)
          .then(result => {
            this.$logger.info('Got markdown content', { moduleMarkdownPageId: this.assetId });
            this.pageContent = result.data.page;
            this.presentationContent = result.data.presentation;
          })
          .catch(err => {
            this.$logger.autowarn('Error getting markdown content', { moduleMarkdownPageId: this.assetId }, err);
            this.showError(err);
          })
          .then(() => {
            this.markdownPageContentReady = true;
          });
      }
    },
  },
};
</script>
