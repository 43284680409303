<template>
  <k-modal :show="true" @close="$emit('close')" class="calendar-modal">
    <template #header>
      <i class="fa-solid fa-square" :class="event.classNames"></i>
      <div class="event-details-content">
        <h2 v-if="moduleIsReleased">
          <router-link v-if="event.event_type === 'calendar_event'"
            :to="{ name: 'module_calendar_event', params: {moduleId: event.module_id, moduleCalendarEventId: parseInt(event.extendedProps.module_asset_id, 10)} }" target="_blank">{{ event.title }}</router-link>
          <router-link v-else-if="event.event_type === 'meeting'"
            :to="{ name: 'module_meeting', params: {moduleId: event.module_id, moduleMeetingId: parseInt(event.extendedProps.module_asset_id, 10) } }" target="_blank">{{ event.title }}</router-link>
          <router-link v-else-if="moduleIsReleased && isModuleEvent" :to="{name: 'module_landing', params: {moduleId: event.module_id}}" target="_blank">{{ event.title }}</router-link>
        </h2>
        <h2 v-else>{{ event.title }}</h2>

        <div v-if="isModuleEvent" class="module-event">
          <h3 :class="event.classNames" v-if="event.event_type === 'module_start'"><i class="fa-duotone fa-hourglass-start"></i> Module Start</h3>
          <h3 :class="event.classNames" v-if="event.event_type === 'module_completion'"><i class="fa-duotone fa-hourglass-end"></i> Module Completion</h3>
          <b>- {{ parseTimestamp(event.start) }}</b>
          <span v-if="!moduleIsReleased">{{ moduleReleaseMessage }}</span>
        </div>

        <div v-else class="event-date">
          <span>{{ parseTimestamp(event.start) }}</span>
          <span v-if="event.end">- {{ parseTimestamp(event.end) }}</span>
          <b v-if="!moduleIsReleased">{{ moduleReleaseMessage }}</b>
        </div>
        <div v-if="!isModuleEvent" class="event-module">
          <i class="fas fa-circle module-completion-dot"></i>
          <div class="event-module-details">
            <div>
              Module: <router-link v-if="moduleIsReleased" :to="{name: 'module_landing', params: {moduleId: event.module_id}}" target="_blank">{{ extendedProps.module_name }}</router-link>
              <span v-else>{{ extendedProps.module_name }}</span>
            </div>
            <span class="start-date">
              Start: <b v-if="extendedProps.module_release_date" :class="dateIsPast(extendedProps.module_release_date) ? '' : 'amber'">{{ parseTimestamp(extendedProps.module_release_date) }}</b>
              <b v-else>{{ moduleReleaseMessage }}</b>
            </span>
            <span v-if="extendedProps.module_expected_completion_date">
              Completion by: <b :class="dateIsPast(extendedProps.module_expected_completion_date) ? 'amber' : 'green'">{{ parseTimestamp(extendedProps.module_expected_completion_date) }}</b>
            </span>
          </div>
        </div>
      </div>
    </template>
  </k-modal>
</template>

<style scoped>
.calendar-modal .modal-header h2 {
  margin: 0;
}

.calendar-modal .module-event {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.calendar-modal .module-event h3.module_completion,
.calendar-modal .module-event .module_completion ~ b {
  color: var(--kate-type-warning);
}

.calendar-modal i.module_completion {
  color: var(--kate-warning);
}

.calendar-modal .module-event h3.module_start,
.calendar-modal .module-event .module_start ~ b {
  color: var(--kate-success-alt);
}

.calendar-modal .module-event span {
  width: 100%;
}

.calendar-modal i.module_start {
  color: var(--kate-success);
}

.calendar-modal .event-date,
.calendar-content .event-module {
  font-size: 1rem;
}

.event-module {
  display: flex;
  margin-top: 15px;
  gap: 5px;
  align-items: baseline;
  margin-left: -20px;
}

.calendar-content .event-module-details {
  display: flex;
  flex-direction: column;
}

.calendar-modal i.fa-square {
  font-size: 1.45em;
}

.calendar-modal i.calendar_event {
  color: var(--kate-type-blue-dark);
}

.calendar-modal i.meeting {
  color: var(--kate-event-alt);
}

.event-date {
  color: var(--kate-type-primary);
}

.module-completion-dot {
  color: var(--kate-type-accent);
  font-size: 0.9em;
}

.start-date {
  color: var(--kate-type-primary);
  font-size: 0.9em;
  margin-bottom: 5px;
}

</style>

<script>
import TimeMixin from '../../mixins/time-mixins';
import KModal from '../k-modal.vue';

export default {
  components: {
    KModal,
  },

  mixins: [TimeMixin],

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  computed: {
    extendedProps() {
      return this.event?.extendedProps || {};
    },

    moduleIsReleased() {
      if (!this.extendedProps?.module_release_date) {
        return false;
      }
      return Boolean(this.getDate(this.extendedProps?.module_release_date) < new Date());
    },
    moduleReleaseMessage() {
      if (!this.extendedProps?.module_release_date) {
        return 'Start date TBC';
      }
      return `Available from ${this.parseTimestamp(this.extendedProps?.module_release_date, undefined, 'do MMM yyyy')}`;
    },

    isModuleEvent() {
      return this.event?.event_type === 'module_start' || this.event?.event_type === 'module_completion';
    },
  },
};
</script>
