<template>
    <k-modal :show="true" @close="$emit('close')" class="calendar-subscription-modal">
      <template #header>
        <h3>Subscribe to Calendar</h3>
      </template>
      <template #body>
        <div v-if="icalSubscriptionId" class="center-align generated-input-container">
          <k-text-input :showReset="false" :readOnly="true" :modelValue="icalUrl" label="Calendar Subscription Link"></k-text-input>
          <button class="btn btn-outlined" @click="copyIcalLink" aria-label="Copy to Clipboard" title="Copy to Clipboard">Copy to Clipboard</button>
          <button ref="regenerateIcalUrlButton" class="btn btn-primary" @click.prevent="regenerateIcalSubscriptionUrl" aria-label="Regenerate Link" title="Regenerate Link">Regenerate Link</button>
          <i v-if="generatingLink" class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else class="center-align">
          <button ref="generateIcalUrlButton" class="btn btn-primary" @click.prevent="generateIcalSubscriptionUrl" aria-label="Generate iCal link" title="Generate iCal link">Generate iCal link</button>
          <i v-if="generatingLink" class="fas fa-spinner fa-spin"></i>
        </div>
        <p>
          Generate an iCal link here to subscribe to this calendar in your own calendar app.
        </p>
        <p>
          For more information refer to your calendar app's documentation.
        </p>
        <p>
          <ul>
            <li><a target="_blank" href="https://support.google.com/calendar/answer/37100">Google Calendar <i class="fad fa-arrow-up-right-from-square"/></a></li>
            <li><a target="_blank" href="https://support.apple.com/en-gb/guide/calendar/icl1022/mac">Apple Calendar <i class="fad fa-arrow-up-right-from-square"/></a></li>
            <li><a target="_blank" href="https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379">Microsoft Outlook <i class="fad fa-arrow-up-right-from-square"/></a></li>
            <li><a target="_blank" href="https://support.apple.com/en-gb/guide/iphone/iph3d1110d4/ios">iPhone <i class="fad fa-arrow-up-right-from-square"/></a></li>
          </ul>
        </p>
      </template>
      <template #footer>
        <button class="btn btn-danger" @click.prevent="$emit('close')" aria-label="Close" title="Close">Close</button>
      </template>
    </k-modal>
</template>

<style>
.generated-input-container button {
  margin: 0 15px;
}

.calendar-subscription-modal .modal-container {
  max-width: 800;
}

.center-align {
  text-align: center;
  padding-bottom: 15px;
}

.btn-primary + .fa-spinner {
  position: absolute;
  margin-left: 8px;
  margin-top: 10px;
}
</style>

<script>
import KModal from '../k-modal.vue';
import KTextInput from '../k-text-input.vue';

export default {
  components: {
    KModal,
    KTextInput,
  },

  props: {
    icalSubscriptionId: Number,
    icalUrl: String,
  },

  data() {
    return {
      generatingLink: false,
    };
  },

  methods: {
    generateIcalSubscriptionUrl() {
      this.generatingLink = true;
      this.$http.post(`/api/curriculum/programmes/${this.$route.params.programmeId}/ical`)
        .then(response => {
          this.$emit('ical-subscription-created', { icalSubscriptionId: response.data.ical_subscription_id, icalUrl: response.data.ical_url });
          this.$ktoast.success('iCal subscription URL generated');
        })
        .catch(err => {
          this.$logger.autowarn('Error generating iCal subscription URL', { programmeId: this.$route.params.programmeId }, err);
          this.$ktoast.warning('Error generating iCal subscription URL');
        }).then(() => {
          this.generatingLink = false;
        });
    },

    regenerateIcalSubscriptionUrl() {
      this.generatingLink = true;
      this.$http.put(`/api/curriculum/programmes/${this.$route.params.programmeId}/ical/${this.icalSubscriptionId}/regenerate`)
        .then(response => {
          this.$emit('ical-subscription-regenerated', { icalUrl: response.data.ical_url });
          this.$ktoast.success('iCal subscription URL regenerated');
        })
        .catch(err => {
          this.$logger.autowarn('Error regenerating iCal subscription URL', { programmeId: this.$route.params.programmeId }, err);
          this.$ktoast.warning('Error regenerating iCal subscription URL');
        }).then(() => {
          this.generatingLink = false;
        });
    },

    copyIcalLink() {
      navigator.clipboard.writeText(this.icalUrl);
      this.$ktoast.info('Copied to clipboard');
    },
  },
};
</script>
