<template>
  <div>
    <div class="gen-feed-banner" :class="genFeedBanner ? '' : 'banner-collapsed'">
      <img v-if="!isError" class="mini-loader" src="../../../../img/Edukate_Loader.svg" alt="Edukate loader"/>
      <i v-else class="fas fa-exclamation-triangle"></i>
      <span>{{ currentMessage }}</span>
    </div>
    <feedback
      :layout="layout"
      :overall-score="overallScore"
      :current-timestamp="currentTimestamp"
      :commit="commit"
      :support-button="true"
      :sha="sha"
      :project-meta="projectMeta"
      :readings="readings"
      :is-loading-feedback="!feedbackReady"
      :validation-result="validationResult"
      :evaluation-result="evaluationResult"
      :extra-point-result="extraPointResult"
      :quality-result="qualityResult"
      :informative-result="informativeResult"
      :marker-errors-result="markerErrorsResult"
      :submission-id="submissionId"
      :allow-submission="false"
      :notebook="notebook"
      :percentile="percentile"
      @rated="(rating) => updateRating(rating)"
    ></feedback>
  </div>
</template>

<style scoped>
.gen-feed-banner {
  display: flex;
  opacity: 1;
  margin: 15px 0;
  gap: 15px;
  height: 105px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--kate-type-dark);
  transition: all 0.3s ease-in-out;
}

.gen-feed-banner.banner-collapsed {
  height: 0;
  margin: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

img.mini-loader {
  width: 55px;
}

.gen-feed-banner span {
  font-size: 1.25em;
  color: var(--kate-type-light);
}

.gen-feed-banner i {
  color: var(--kate-gold);
}
</style>

<script>
import Feedback from '../../../feedback/feedback.vue';
import ErrorMixin from '../../../mixins/error-mixins';
import PageReadyMixin from '../../../mixins/page-ready-mixin';

const REFRESH_INTERVAL = 1000;

export default {
  props: {
    projectMeta: Object,
    projectDetails: Object,
    notebook: Object,
    enableReadings: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    feedback: Feedback,
  },
  mixins: [ErrorMixin, PageReadyMixin],

  data() {
    return {
      validationResult: undefined,
      evaluationResult: undefined,
      extraPointResult: undefined,
      qualityResult: undefined,
      informativeResult: undefined,
      markerErrorsResult: undefined,
      layout: undefined,
      overallScore: 0,
      currentTimestamp: undefined,
      latestEventTimestamp: undefined,
      commit: undefined,
      sha: undefined,
      submissionId: undefined,
      refreshInterval: undefined,
      genFeedBanner: false,
      readings: undefined,
      feedbackReady: false,
      readingsReady: false,
      percentile: undefined,
      currentMessage: '',
      isError: false,
    };
  },

  beforeMount() {
    this.getStudentFeedback();
    this.getReadings();
  },

  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },

  computed: {
    modulePakId() {
      return this.$route.params.modulePakId;
    },
    ready() {
      return this.feedbackReady && this.readingsReady;
    },
    feedbackUrl() {
      if (this.projectMeta.runners && this.projectMeta.runners.jobs && this.projectMeta.runners.jobs.includes('extra_points')) {
        return `/api/stats/${this.modulePakId}/feedback?percentile=True`;
      }
      return `/api/stats/${this.modulePakId}/feedback`;
    },
  },

  methods: {
    updateRating(value) {
      const { rating, index, id } = value;
      const rec = this.readings[index];
      rec.rating = rating;
      this.readings.splice(index, 1, rec);
      this.$logger.info('Updating learning resource rating', { learningResourceId: id });
      this.$http.put(`/api/curriculum/pak/reading/${this.submissionId}/${id}/set-feedback?rating=${rating}`)
        .then(() => {
          this.$logger.info('Updated learning resource rating', { learningResourceId: id }, true);
        })
        .catch(err => {
          this.$logger.error(
            'Error updating learning resource rating',
            { learningResourceId: id },
            err,
          );
          this.showError(err, true);
        });
    },
    getReadings() {
      if (this.enableReadings) {
        this.$logger.info('Getting readings for pak', { modulePakId: this.modulePakId });
        this.readingsReady = false;
        this.$http
          .get(`/api/curriculum/pak/reading/${this.modulePakId}`)
          .then(response => {
            this.$logger.info('Got readings for pak', { modulePakId: this.modulePakId });
            this.readings = response.data.readings;
          }).catch(err => {
            this.$logger.autowarn('Error getting readings for pak', { modulePakId: this.modulePakId }, err);
            this.showError(err, true);
          })
          .then(() => {
            this.readingsReady = true;
          });
      }
    },
    startRefreshCommit() {
      this.$logger.info('Starting recurring fresh commit check', { modulePakId: this.modulePakId });
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(() => {
        this.checkForUpdatesCommit();
      }, REFRESH_INTERVAL);
    },
    setBannerMessage(message, isError = false) {
      if (message) {
        this.currentMessage = message;
        this.genFeedBanner = true;
        this.isError = isError;
      } else {
        this.genFeedBanner = false;
        this.isError = false;
      }
    },
    startLoading() {
      this.setBannerMessage('Generating Feedback...');
    },
    stopLoading() {
      this.setBannerMessage();
    },
    updateFeedback() {
      clearInterval(this.refreshInterval);
      this.getStudentFeedback();
      this.getReadings();
    },
    markMessageAsRead() {
      this.$http.put(`/api/events/submissions/${this.projectMeta.id}`).catch(err => {
        this.$logger.warn('Could not mark event as read', err);
      });
    },
    getErrorMessage(code) {
      const messages = {
        deprecated: 'This assignment is no longer supported.',
        rate_limit_exceeded: 'Too many submissions. Please wait before submitting again.',
        timer_exceeded: 'Timer has elapsed. You may no longer submit.',
      };
      return messages[code] || 'There was an error with your submission. Please try again.';
    },
    handleGenerationError(err, timestamp) {
      if (!this.latestEventTimestamp || (timestamp >= this.latestEventTimestamp)) {
        this.latestEventTimestamp = timestamp;
        this.markMessageAsRead();
        const message = this.getErrorMessage(err);
        this.setBannerMessage(`Error generating feedback: ${message}`, true);
      }
    },
    handleCommitMessage(msg) {
      if (!msg || msg.read) {
        return;
      }
      if (msg.status === 'in_progress' && (!this.currentTimestamp || ((msg.timestamp > this.currentTimestamp)))) {
        this.startLoading();
        return;
      }
      if (msg.status === 'failure') {
        this.handleGenerationError(msg.message, msg.timestamp);
      }
      if (msg.status === 'success' && ((!this.currentTimestamp) || (msg.timestamp > this.currentTimestamp))) {
        this.updateFeedback();
        this.stopLoading();
      }
    },
    checkForUpdatesCommit() {
      this.$http
        .get(`/api/events/submissions/${this.projectMeta.id}`)
        .then(res => {
          this.handleCommitMessage(res.data.submission);
        })
        .catch(err => {
          console.log(err); // eslint-disable-line no-console
        });
    },
    isEmpty(Obj) {
      return Object.keys(Obj).length === 0;
    },
    getStudentFeedback() {
      this.$logger.info('Getting latest feedback', { modulePakId: this.modulePakId });
      this.feedbackReady = false;
      this.$http
        .get(this.feedbackUrl)
        .then(result => {
          if (this.isEmpty(result.data)) {
            this.$logger.warn('Feedback returned as empty object', { modulePakId: this.modulePakId });
          } else if (this.isEmpty(result.data.feedback.meta.layout)) {
            this.$logger.warn('Feedback returned with empty layout object', { modulePakId: this.modulePakId });
          } else {
            this.layout = result.data.feedback.meta.layout;
            this.overallScore = result.data.feedback.overall_score;
            this.currentTimestamp = result.data.feedback.meta.date_timestamp;
            this.latestEventTimestamp = result.data.feedback.meta.date_timestamp;
            this.commit = result.data.feedback.meta.commit_title;
            this.sha = result.data.feedback.meta.commit_sha;
            this.submissionId = result.data.feedback.meta.submission_id;
            this.validationResult = result.data.feedback.validation_result;
            this.evaluationResult = result.data.feedback.evaluation_result;
            this.extraPointResult = result.data.feedback.extra_points_result;
            this.qualityResult = result.data.feedback.quality_result;
            this.markerErrorsResult = result.data.feedback.marker_errors;
            this.informativeResult = result.data.feedback.informative_result;
            this.percentile = result.data.feedback.percentile;
            this.updatePakScore();
            this.updatePakCompletion();
            this.checkIfBadgesAchieved();
          }
          this.$logger.info('Got latest feedback', { modulePakId: this.modulePakId });
        })
        .catch(err => {
          if (this.$http.errIn(err, [409])) {
            this.$logger.warn('Unsupported feedback version', { modulePakId: this.modulePakId });
          } else if (this.$http.isWarning(err)) {
            this.$logger.warn('No feedback found', { modulePakId: this.modulePakId });
          } else {
            this.$logger.error('Error getting feedback', { modulePakId: this.modulePakId }, err);
            this.showError(err, true);
          }
        })
        .then(() => {
          this.stopLoading();
          this.feedbackReady = true;
          this.startRefreshCommit();
        });
    },
    updatePakScore() {
      this.$sidebar.updateAssetScore('pak', this.projectMeta.id, this.overallScore);
    },
    updatePakCompletion() {
      if (this.evaluationResult && this.evaluationResult.passed) {
        this.$sidebar.updateCompletionStatus('pak', this.projectMeta.id, true);
      } else {
        this.$sidebar.updateCompletionStatus('pak', this.projectMeta.id, false);
      }
    },
    checkIfBadgesAchieved() {
      this.$http.post(`/api/curriculum/pak/${this.modulePakId}/badge`).then(res => {
        this.$ktoast.showBadgesAchieved(res.data);
      }).catch(err => {
        this.$logger.autowarn('Error checking for badges', { modulePakId: this.modulePakId }, err);
      });
    },
  },
};
</script>
