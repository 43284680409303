<template>
  <section v-if="ready" class="profile-badges content-with-spacing">
    <div class="heading-container content-with-spacing">
      <div class="title-container">
        <h2 v-if="orderedBadges.length">Badges Achieved</h2>
      </div>
      <div v-if="availableProgrammes.length > 1" class="header-dropdown">
        <span class="dropdown-label">Programmes:</span>
        <k-dropdown class="programme-dropdown" :options="availableProgrammes" v-model="currentProgramme"></k-dropdown>
      </div>
    </div>
    <div v-if="orderedBadges.length || completionCertificate">
      <div v-if="orderedBadges.length">
        <k-progress class="badgeProgress"
                    :percentage="percentOfBadgesAchieved"
                    :customText="' of badges awarded'"
                    ariaLabel="Percentage of badges achieved"
                    :precision="2"></k-progress>
        <div v-for="badge in orderedBadges" :key="badge.id">
          <achievement-award-card :achievement="badge" :enableShareOnLinkedIn="true"></achievement-award-card>
        </div>
      </div>
      <div v-if="completionCertificate">
        <div class="title-container" v-if="orderedBadges.length">
          <h2>Completion Certificate</h2>
        </div>
        <achievement-award-card
          :achievement="completionCertificate"
          :enableShareOnLinkedIn="true"
          :achievementType="'certificate'"></achievement-award-card>
      </div>
    </div>
    <empty-placeholder v-else
                       :title="'Completion Achievements'"
                       :info="'No achievements to display for this programme'"
                       :fa-icon="'fas fa-certificate'"
    ></empty-placeholder>
  </section>
</template>

<style scoped>
.heading-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
  width: 100%;
}

.title-container {
  width: 40%;
}

.header-dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-dropdown > div {
  min-width: 250px;
}

.badgeProgress {
  margin-bottom: 20px;
}
</style>

<script>
import AchievementAwardCard from '../dashboard/achievements/achievement-award-card.vue';
import StorageMixin from '../mixins/storage-mixins';
import KProgress from '../components/k-progress.vue';
import { sortObjectArray } from '../modules/sort-by-object-property';
import KDropdown from '../components/k-dropdown.vue';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';
import EmptyPlaceholder from '../components/empty-placeholder.vue';

export default {
  components: {
    EmptyPlaceholder,
    KDropdown,
    KProgress,
    AchievementAwardCard,
  },

  mixins: [StorageMixin, ErrorMixin, PageReadyMixin],

  data() {
    return {
      badges: [],
      badgesReady: false,
      availableProgrammes: [],
      availableProgrammesReady: false,
      currentProgramme: undefined,
      certificatesReady: false,
      completionCertificate: undefined,
    };
  },

  watch: {
    currentProgramme() {
      this.getBadges();
      this.getCertificates();
    },
  },

  beforeMount() {
    this.currentProgramme = this.loadSetting('programme_page', 'currentProgramme');
    this.getAvailableProgrammes();
  },

  computed: {
    ready() {
      return this.badgesReady && this.certificatesReady && this.availableProgrammesReady;
    },
    orderedBadges() {
      return sortObjectArray(this.badges || [], 'date_issued', true);
    },
    percentOfBadgesAchieved() {
      if (!this.badges?.length) {
        return 0;
      }
      return (this.badges.filter(x => x.has_been_awarded).length / this.badges.length) * 100;
    },
  },

  methods: {
    getBadges() {
      this.$logger.info('Getting badges for programme', { progId: this.currentProgramme });
      this.badgesReady = false;
      this.badges = [];
      this.$http.get(`/api/curriculum/programmes/${this.currentProgramme}/badges/user`).then(res => {
        this.badges = res.data;
        this.$logger.info('Got badges for programme', { progId: this.currentProgramme });
      }).catch(err => {
        this.$logger.error('Error getting badges for programme', { progId: this.currentProgramme }, err);
        this.showError(err);
      }).then(() => {
        this.badgesReady = true;
      });
    },
    getCertificates() {
      this.$logger.info('Getting certificate for user in programme', { progId: this.currentProgramme });
      this.certificatesReady = false;
      this.completionCertificate = undefined;
      this.$http.get('/api/curriculum/programmes/certificates/user').then(res => {
        this.completionCertificate = res.data.find(c => c.programme_id === this.currentProgramme);
        this.$logger.info('Got certificate for user in programme', { progId: this.currentProgramme });
      }).catch(err => {
        this.$logger.autowarn('Error getting certificate for user in programme', { progId: this.currentProgramme }, err);
        this.showError(err);
      }).then(() => {
        this.certificatesReady = true;
      });
    },
    getAvailableProgrammes() {
      this.availableProgrammesReady = false;
      this.$logger.info('Getting available programmes');
      this.$http.get('/api/curriculum/programmes')
        .then(response => {
          this.$logger.info('Got available programmes');
          this.availableProgrammes = response.data.programmes;
          if (!this.currentProgramme) {
            this.$logger.info('No programme saved in storage - defaulting to first available');
            this.currentProgramme = this.availableProgrammes[0].id;
          }
        })
        .catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.availableProgrammesReady = true;
        });
    },
  },
};
</script>
