<template>
  <li :class="{'completed': calendarEvent.is_complete}">
    <i class="k-asset-icon" :class="getAssetIcon('calendar_event')"></i>
    <div class="asset-details">
      <router-link :to="{ name: 'module_calendar_event', params: { moduleId: moduleId, moduleCalendarEventId: calendarEvent.id }}" class="router-link">
        {{ calendarEvent.name}}
      </router-link>
      <span v-if="calendarEvent.event_start" class="asset-due-date">
        From: <b>{{ parseTimestamp(calendarEvent.event_start, undefined, "do MMM yyyy HH:mm") }}</b><i v-if="!calendarEvent.event_start">To be confirmed</i>
        Until: <b>{{ parseTimestamp(calendarEvent.event_end, undefined, "do MMM yyyy HH:mm") }}</b><i v-if="!calendarEvent.event_end">To be confirmed</i>
      </span>
      <k-asset-tags :asset="calendarEvent"></k-asset-tags>
    </div>
    <i v-if="calendarEvent.is_complete" class="fas fa-check"></i>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_calendar_event', params: { moduleId: moduleId, moduleCalendarEventId: calendarEvent.id }}">
      View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
import TimeMixin from '../mixins/time-mixins';
import AssetIconMixin from '../mixins/asset-icon-mixin';
import KAssetTags from '../components/k-asset-tags.vue';

export default {
  mixins: [TimeMixin, AssetIconMixin],
  props: {
    calendarEvent: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
  },

  components: {
    KAssetTags,
  },
};
</script>
