<template>
  <div v-if="moduleDetails">
    <div class="content-with-spacing">
      <div class="heading-container">
        <h1>{{ moduleDetails.name }}</h1>
      </div>
      <div class="threads-container">
        <knowledge-base
          :sidebarThreads="sidebarThreads"
          @refresh="$sidebar.refreshThreads()"
        ></knowledge-base>
      </div>
    </div>
  </div>
</template>

<style scoped>
.heading-container {
  margin: 20px auto;
}

.category-header {
  margin-bottom: 20px;
}
</style>

<style>
#content .heading-container h1 {
  margin: 0;
}
</style>

<script>
import KnowledgeBase from '../knowledge_base/knowledge-base.vue';

export default {
  components: {
    KnowledgeBase,
  },

  props: {
    moduleDetails: {
      type: Object,
    },
  },

  mounted() {
    this.registerCrumbs();
  },

  watch: {
    moduleDetails() {
      if (this.moduleDetails && !this.moduleDetails.knowledge_base_enabled) {
        this.$logger.warn('Attempted to access disabled knowledge base - route to 404');
        this.$router.push({ name: '404' });
      } else {
        // Re-register crumbs if module changes
        this.registerCrumbs();
      }
    },
  },

  computed: {
    moduleId() {
      return this.$route.params.moduleId;
    },

    sidebarThreads() {
      return this.$sidebar.threads;
    },
  },

  methods: {
    registerCrumbs() {
      if (!this.moduleDetails) {
        return;
      }
      this.$crumbs.register([
        {
          text: this.moduleDetails.programme_name,
          path: {
            name: 'programme_page',
            params: { programmeId: this.moduleDetails.programme_id },
          },
        },
        {
          text: this.moduleDetails.name,
          path: {
            name: 'module_landing',
            params: { moduleId: this.moduleId },
          },
        },
        {
          text: 'Knowledge Base',
          active: true,
        },
      ]);
    },
  },
};
</script>
