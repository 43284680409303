<template>
  <li :class="{'completed': meeting.is_complete}">
    <i class="k-asset-icon" :class="getAssetIcon('meeting')"></i>
    <div class="asset-details">
      <router-link :to="{ name: 'module_meeting', params: { moduleId: moduleId, moduleMeetingId: meeting.id }}" class="router-link">
        {{ meeting.name}}
      </router-link>
      <span v-if="meeting.meeting_start && meeting.meeting_end" class="asset-due-date">
        <div>
          From: <b>{{ parseTimestamp(meeting.meeting_start, undefined, "do MMM yyyy HH:mm") }}</b>
        </div>
        <div>
          To: <b>{{ parseTimestamp(meeting.meeting_end, undefined, "do MMM yyyy HH:mm") }}</b>
        </div>
      </span>
      <span v-else-if="meeting.meeting_start" class="asset-due-date">
        At: <b>{{ parseTimestamp(meeting.meeting_start, undefined, "do MMM yyyy HH:mm") }}</b>
      </span>
      <span v-else class="asset-due-date">
        <i>To be confirmed</i>
      </span>
      <k-asset-tags :asset="meeting"></k-asset-tags>
    </div>
    <i v-if="meeting.is_complete" class="fas fa-check"></i>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_meeting', params: { moduleId: moduleId, moduleMeetingId: meeting.id }}">
      View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
import TimeMixin from '../mixins/time-mixins';
import AssetIconMixin from '../mixins/asset-icon-mixin';
import KAssetTags from '../components/k-asset-tags.vue';

export default {
  mixins: [TimeMixin, AssetIconMixin],
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
  },

  components: {
    KAssetTags,
  },
};
</script>
