<template>
  <div v-if="video" class="category-content">
    <div class="video-container">
      <div class="featured-video">
        <figure ref="figure">
          <video
            :class="{fullscreen : isFullScreen}"
            @click="playOrPause" ref="player"
            controlsList="nodownload"
            :src="videoSignedUrl"
            @timeupdate="updateTime"
            @durationchange="updateDuration"
            @ended="pause"
            @volumechange="updateVolume"
            @loadeddata="loadVideoInit"
            >
            <!-- Fallback source -->
            <source :src="videoSignedUrl" type="video/mp4">
            <source :src="videoSignedUrl" type="video/WebM">
            Your browser does not support HTML video.
          </video>
          <!-- VIDEO CUSTOM CONTROLS -->
          <div v-if="ready" :class="{'fullscreen-controls' : isFullScreen}" class="controls-container">

            <div :style="progressBarStyle" :class="{grabbed : isGrabbingSeekbar}" ref="progressBar" class="video-track" id="video-player-media"
              @touchmove="moveSeekbar"
              @click="moveSeekbar"
              @mousedown="grabSeekbar"
              @touchstart="grabSeekbar"
              @mousemove="moveIfGrabbing"
              @mouseup="releaseSeekbar"
              @touchend="releaseSeekbar"
            ></div>

            <div class="left-controls">
              <!-- Play / Pause Button -->
              <button type="button" class="btn video-controls" title="Play" aria-label="Play" @click="playOrPause"><i class="fas " :class="playIcon"></i></button>
              <!-- Video Current Time / Overall Duration-->
              <div class="video-duration">
                <span>{{ videoCurrentTime }}</span>/
                <span>{{ videoDuration }}</span>
              </div>
              <!-- Rewind 10s-->
              <button @click="skipBackward" type="button" class="btn video-controls btn-skip backward-btn" title="Rewind 10s" aria-label="Rewind 10s">-10s <i class="fas fa-undo-alt"></i></button>
              <!-- Forward 10s-->
              <button @click="skipForward" type="button" class="btn video-controls btn-skip forward-btn" title="Forward 10s" aria-label="Forward 10s"><i class="fas fa-redo-alt"></i> +10s</button>
            </div>
            <div class="right-controls">
              <!-- Volume Settings -->
              <div class="control-settings volume-container">
                <button @click="muteVideo" type="button" class="btn video-controls mute" title="Mute" aria-label="Mute"><i class="fas" :class="muteIcon"></i></button>
                <div class="volume-track">
                  <span class="volume-value">{{ Math.round(volume * 100) }}%</span>
                  <input v-model.number="volume" ref="volumeBar" class="volume-bar" title="volume" aria-label="Volume" type="range" min="0" step="0.01" max="1">
                </div>
              </div>
              <!-- Control Settings -->
              <div class="control-settings">
                <button class="btn video-controls video-settings" :class="{ opened : showControls }" @click="showControls = !showControls" type="button" title="Playback speed" aria-label="Playback speed"><i class="fas fa-cog"></i></button>
                  <ul class="control-settings-panel">
                    <li class="control-settings-list" :class="{ expanded : showSubSettings}">
                      <a class="control-settings-item"  @click="showSubSettings = !showSubSettings">
                        <span>Playback Speed</span>
                        <span>{{ currentPlaybackRate }} <i class="fas fa-angle-right"></i></span>
                      </a>
                    </li>
                    <li class="playback-values">
                      <a @click="showSubSettings = !showSubSettings"><i class="fas fa-angle-left"></i> Back</a>
                      <a class="playback" v-for="playBack in playbackRateOptions" :key="playBack" :class="currentPlaybackRate === playBack ? 'selected' : ''" @click="setPlayBackRate(playBack)">{{ playBack }} </a>
                    </li>
                  </ul>
              </div>
              <!-- Picture-in-picture -->
              <button v-if="detectBrowser.isChrome || detectBrowser.isSafari" @click="togglePictureInPicture" type="button" class="btn video-controls video-pip-btn" title="Picture in picture" aria-label="Picture in picture"><img class="video-pip-icon" src="../../img/icons/pip.svg" alt="video picture in picture icon"></button>
              <!-- Full Screen -->
              <button @click="toggleFullScreen" type="button" class="btn video-controls expand-fullscreen" title="Fullscreen" aria-label="Fullscreen"><i class="fas " :class="isFullScreen ? 'fa-compress' : 'fa-expand'"></i></button>
            </div>
          </div>
        </figure>

        <div class=video-details-container>
          <h2 class="video-title"><i class="fas fa-film"></i>{{ video.name }}</h2>
          <span class="current-video-date"><small>{{ formattedDate }}</small></span>
          <p class="current-video-description">{{ video.description }}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.row.video-container {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 15px;
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
}

.video-title {
  width: 100%;
  margin-bottom: 15px;
}

.featured-video {
  flex: 1;
  text-align: center;
  max-width: 1300px;
  margin: 0 auto;
}

.featured-video > figure > video {
  max-height: 650px;
  max-width: 100%;
  border: 1px solid var(--kate-background-body);
  background-color: var(--kate-background);
}

.featured-video > figure > video:focus {
  outline: none;
}

.video-details-container {
  border-left: 3px solid var(--kate-background-body);
  text-align: left;
  padding: 25px 25px 5px;
}

.video-details-container h2 {
  margin: 0;
}

.video-details-container h2 i {
  margin-right: 10px;
  color: var(--kate-primary);
}

.video-details-container .current-video-date {
  display: block;
  padding: 10px 0 0;
}

.video-details-container p {
  margin-top: 10px;
  color: var(--kate-type-light);
}

/* Video Playlist */
.video-gallery {
  width: 30%;
  text-align: center;
  margin-left: 25px;
  max-height: 500px;
  overflow: auto;
}

.video-gallery::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  box-shadow: none;
}

.video-gallery::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.video-gallery ul {
  text-align: center;
  padding: 0;
  list-style: none;
  margin: 0;
}

.video-gallery-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 7px 5px 7px 0;
  align-items: center;
  border-bottom: 1px solid var(--kate-background-alt-alpha);
  background-color: var(--kate-background-body-alpha);
}

.video-gallery-item:hover {
  text-decoration: none;
  background-color: var(--kate-background-body);
}

.video-gallery-item video {
  margin-right: 5px;
  width: 100%;
  max-width: 80px;
  min-height: 80px;
}

.current-vid {
  background-color: var(--kate-background-body);
}

.video-gallery-item .video-title-container {
  text-align: left;
  flex: 1;
}

.video-gallery-item .current-video-playing-indicator {
  width: 24px;
  margin-left: -2px;
}

.current-video-playing-indicator i {
  font-size: 45px;
  color: var(--kate-type-dark);
  text-align: left;
  width: 100%;
}

.video-gallery-item h5 {
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  color: var(--kate-type-light);
}

.video-gallery-item span {
  color: var(--kate-type-primary);
  margin: 0;
  padding: 0;
  font-size: 11px;
  line-height: 18px;
}

figure {
  position: relative;
}

video + .controls-container {
  opacity: 0;
  z-index: -1;
  transition: all 0.2s ease-in;
}

.controls-container:hover,
video:hover + .controls-container {
  opacity: 1;
  z-index: 2;
}

.controls-container.fullscreen-controls:hover,
video:hover + .controls-container.fullscreen-controls {
  z-index: 2147483647;
}

/* Controls */
.controls-container {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--kate-background);
  justify-content: space-between;
  font-size: 0.85em;
  padding: 10px;
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
}

.left-controls,
.right-controls {
  display: flex;
  align-items: center;
}

.right-controls .video-controls {
  margin-right: 0;
  margin-left: 14px;
}

.video-controls {
  line-height: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 14px;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  color: var(--kate-type-light);
  position: relative;
  padding: 0;
}

.video-duration {
  margin-right: 10px;
}

.video-controls.btn-skip {
  width: unset;
  height: unset;
  border-radius: 4px;
  margin-right: 5px;
  padding: 8px;
}

.video-controls:focus {
  outline: none;
}

.video-controls.opened,
.video-controls:hover {
  color: var(--kate-type-light);
  background-color: var(--kate-background-alt-alpha);
}

.control-settings {
  position: relative;
}

/* Picture in Picture custom icon */
.video-pip-icon {
  width: 15px;
  position: absolute;
  left: 52%;
  right: 0;
  top: 11px;
  transform: translateX(-50%);
}

/* Volume */
.control-settings.volume-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.volume-container .video-controls {
  margin-left: 0;
}

.volume-track {
  position: absolute;
  left: 50%;
  bottom: 85px;
  padding: 20px;
  min-height: 30px;
  box-sizing: border-box;
  transform: translateX(-50%) rotate(-90deg);
  background-color: var(--kate-background);
  border-radius: 4px;
}

input[type="range"] {
  appearance: none;
  height: 5px;
  width: 100px;
  outline: none;
  border-radius: 4px;
  background-color: var(--kate-light-teal);
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  width: 9px;
  height: 9px;
  background-color: var(--kate-primary);
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 5px;
  height: 17px;
  background-color: var(--kate-primary);
  cursor: pointer;
  border-radius: 1px;
  outline: none;
  border: 0;
}

.volume-value {
  transform: translateY(130%) rotate(90deg);
  position: absolute;
  left: 115px;
  right: unset;
  bottom: 50%;
  z-index: 0;
  top: 1px;
  background-color: var(--kate-background);
  border-radius: 4px;
  height: 20px;
  padding: 5px;
  width: 43px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

/* Volume Control */
.volume-container:hover .video-controls::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 20px;
  right: 0;
  height: 50px;
  z-index: 0;
}

.control-settings.volume-container:hover .volume-track {
  opacity: 1;
  z-index: 1;
  transition: all 0.2s;
}

.control-settings.volume-container .volume-track {
  opacity: 0;
  z-index: -1;
}

/* Control settings opening behaviour */
.video-controls + .control-settings-panel {
  position: absolute;
  bottom: 46px;
  left: 70%;
  background-color: var(--kate-background);
  padding: 10px 5px;
  border-radius: 4px;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  text-align: left;
  transform: translateX(-50%);
}

.video-controls + ul.control-settings-panel,
.control-settings-list + li.playback-values {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.control-settings-list + li.playback-values {
  height: 0;
}

.video-controls.opened + ul.control-settings-panel,
.control-settings-list.expanded + li.playback-values {
  z-index: 1;
  opacity: 1;
  pointer-events: auto;
}

.control-settings-list.expanded + li.playback-values {
  height: auto;
}

li.playback-values .playback.selected {
  color: var(--kate-primary);
  background-color: var(--kate-panel-alt);
  font-weight: bold;
}

.control-settings-list {
  width: 200px;
  transition: width 0.1s, opacity 0.3s;
  white-space: nowrap;
  opacity: 1;
}

.control-settings-list.expanded {
  display: none;
  width: 0;
  opacity: 0;
  height: 0;
  transition: all 0s;
}

.control-settings-panel .playback-values a:first-child {
  padding: 7px 5px;
  align-items: center;
  border-bottom: var(--border-primary);
  border-radius: 0;
}

.control-settings-panel {
  list-style: none;
  padding: 0;
  margin: 0;
}

.control-settings-panel li > a {
  padding: 7px 15px;
  color: var(--kate-type-light);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}

.control-settings-panel li a:hover {
  background-color: var(--kate-background-alt-alpha);
  text-decoration: none;
}

/* Video track (seekbar) */
.video-track {
  cursor: pointer;
  position: relative;
  width: 100%;
  border-radius: 4px;
  height: 6px;
  margin-bottom: 5px;
}

.grabbed {
  cursor: grabbing;
}

/* Full Screen */

/* Remove the native controls */
video::-webkit-media-controls {
  display: none !important;
}

.controls-container.fullscreen-controls {
  bottom: 0;
  z-index: 2147483647;
  width: 90%;
  margin: 0 auto;
}

/* Set a delayed fade out of the controls on the main video container in when it's hovered */
video:hover + .controls-container {
  animation: 3s ease-in-out 0s normal forwards 1 fadeout;
}

@keyframes fadeout {
  0% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}

.featured-video > figure > video.fullscreen {
  max-height: 100%;
}

@media screen and (max-width: 900px) {
  .featured-video {
    width: 100%;
  }

  .featured-video > figure > video {
    max-width: 100%;
    width: 100%;
  }

  .video-gallery {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }

  .video-gallery ul {
    width: 100%;
    display: flexbox;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow: auto;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .video-gallery ul::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    box-shadow: none;
  }

  .video-gallery ul::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .video-gallery-item {
    align-items: baseline;
    border: 0;
    padding: 10px;
    border-right: 3px solid #02404b;
    width: 100%;
  }

  .video-gallery-item span {
    display: block;
  }

  .video-gallery-item video {
    margin: 0;
    max-width: 180px;
    min-width: 100px;
    padding: 10px;
  }

  .video-gallery-item:last-child {
    border-right: 0;
  }

  .video-gallery-item .current-video-playing-indicator {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .video-duration {
    display: none;
  }
}

</style>

<script>
import TimeMixins from '../mixins/time-mixins';
import ErrorMixin from '../mixins/error-mixins';

export default {
  props: {
    moduleId: {
      type: Number,
    },
    video: {
      type: Object,
      required: true,
    },
  },

  mixins: [TimeMixins, ErrorMixin],

  data() {
    return {
      videoSignedUrl: undefined,
      ready: false,
      isGrabbingSeekbar: false,
      time: 0,
      duration: 0,
      isPlaying: false,
      isMuted: false,
      volume: '0.1',
      currentPlaybackRate: 1,
      playbackRateOptions: [
        0.25,
        0.5,
        0.75,
        1,
        1.25,
        1.5,
        1.75,
        2,
      ],
      showControls: false,
      showSubSettings: false,
      isFullScreen: false,
    };
  },

  beforeMount() {
    document.fullscreenElement = document.fullscreenElement || document.mozFullscreenElement
          || document.msFullscreenElement || document.webkitFullscreenDocument || document.webkitFullscreenElement;

    document.exitFullscreen = document.exitFullscreen || document.mozExitFullscreen
          || document.msExitFullscreen || document.webkitExitFullscreen;
  },

  mounted() {
    this.getSignedUrl(this.video.id);
  },

  watch: {
    ready() {
      this.$emit('ready', this.ready);
    },
    volume() {
      this.$refs.player.volume = this.volume;
    },
    video() {
      this.getSignedUrl(this.video.id);
    },
  },

  computed: {
    formattedDate() {
      return this.parseTimestamp(this.video.date_recorded, undefined);
    },

    getProgressRate() {
      return this.time / this.duration;
    },

    progressBarStyle() {
      return {
        background: `linear-gradient(90deg, #5cfff3 ${this.getProgressRate * 100}%, rgb(84, 84, 84) 0%)`,
      };
    },

    videoCurrentTime() {
      return this.convertSecondsToTime(this.time);
    },

    videoDuration() {
      return this.convertSecondsToTime(this.duration);
    },

    playIcon() {
      if (this.isPlaying && this.time > 0) {
        return 'fa-pause';
      }
      return 'fa-play';
    },

    muteIcon() {
      if (this.isMuted || this.volume === 0) {
        return 'fa-volume-mute';
      } if (this.volume >= 0.1 && this.volume <= 0.5) {
        return 'fa-volume-down';
      }
      return 'fa-volume-up';
    },
  },

  methods: {
    getSignedUrl(moduleVideoId) {
      this.ready = false;
      this.$logger.info('Getting video source with signed URL', { moduleVideoId });
      this.$http.get(`/api/curriculum/video/${moduleVideoId}/watch`)
        .then(result => {
          this.$logger.info('Got video source', { moduleVideoId });
          this.videoSignedUrl = result.data.src;
        }).catch(err => {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Error getting signed URL', { moduleVideoId }, err);
            this.showError(err, true);
          } else {
            this.$logger.error('Error getting signed URL', { moduleVideoId }, err);
            this.showError(err, true);
          }
        }).then(() => {
          this.ready = true;
        });
    },

    seekTo(val) {
      if (Number.isFinite(val)) {
        this.$refs.player.currentTime = val;
      } else {
        this.$logger.warn('Invalid time value for video', { timeValue: val });
      }
    },

    updateTime(ev) {
      this.time = ev.target.currentTime;
    },

    updateDuration(ev) {
      this.duration = ev.target.duration;
    },

    updateVolume(ev) {
      this.volume = ev.target.volume;
    },

    loadVideoInit(ev) {
      this.volume = ev.target.volume;
    },

    /* Play / Pause */
    play() {
      this.$refs.player.play();
      this.isPlaying = true;
      // Log this to events table
      this.$logger.info('Started video playback', { videoId: this.video.video_id, moduleId: this.moduleId }, true);
    },

    pause() {
      this.$refs.player.pause();
      this.isPlaying = false;
    },

    playOrPause() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },

    muteVideo() {
      this.$refs.player.muted = !this.$refs.player.muted;
      this.isMuted = !this.isMuted;
    },

    // Go back 10s
    skipBackward() {
      const val = this.$refs.player.currentTime - 10;
      this.seekTo(val);
    },

    // Go forward 10s
    skipForward() {
      const val = this.$refs.player.currentTime + 10;
      this.seekTo(val);
    },

    // Set playback rate
    setPlayBackRate(val) {
      if (val) {
        this.$refs.player.playbackRate = val;
        this.currentPlaybackRate = val;
        this.showControls = false;
        this.showSubSettings = false;
      }
    },

    togglePictureInPicture() {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      } else {
        this.$refs.player.requestPictureInPicture();
      }
    },

    toggleFullScreen() {
      if (this.detectBrowser.isSafari) {
        if (document.webkitFullscreenElement) {
          document.exitFullscreen();
          this.isFullScreen = false;
        } else {
          this.$refs.figure.webkitRequestFullscreen();
          this.isFullScreen = true;
        }
      } else {
        if (document.fullscreenElement) { // eslint-disable-line no-lonely-if
          document.exitFullscreen().then(() => {
            this.isFullScreen = false;
          });
        } else {
          this.$refs.figure.requestFullscreen().then(() => {
            this.isFullScreen = true;
          });
        }
      }
    },

    grabSeekbar(event) {
      this.isGrabbingSeekbar = true;
      const val = (event.offsetX / event.currentTarget.scrollWidth) * this.duration;
      this.seekTo(val);
    },

    moveSeekbar(event) {
      event.preventDefault();
      const val = (event.offsetX / event.currentTarget.scrollWidth) * this.duration;
      this.seekTo(val);
    },

    moveIfGrabbing(event) {
      if (this.isGrabbingSeekbar) {
        this.moveSeekbar(event);
      }
    },

    releaseSeekbar(event) {
      event.preventDefault();
      this.isGrabbingSeekbar = false;
      if (this.isPlaying) {
        this.play();
      }
    },

    convertSecondsToTime(time) {
      const seconds = Math.floor(time % 60).toString().padStart(2, 0);
      const minutes = Math.floor((time / 60) % 60).toString().padStart(2, 0);
      const hours = Math.floor((time / 3600)).toString().padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },

  },
};
</script>
