<template>
  <section class="container">
    <h1>Welcome
      <b v-if="assetName">to {{ assetName }}</b>
      <span v-if="version" class="sub-title">{{ version }}</span>
    </h1>
    <p> Welcome to the KATE Learning Environment, use the controls below to access tools available and manage your environment.
      Note that the environment is specific to the content you have opened</p>
    <div class="service-items">
      <a class="service-btn btn btn-primary" :href="`${kodUrl}&path=/dev`" target="_blank">Notebooks</a>
      <a class="service-btn btn btn-primary" :href="`${kodUrl}&path=/code`" target="_blank">VsCode</a>
    </div>
  </section>
</template>

<style scoped>
section.container {
  margin: 10% auto;
  text-align: center;
}

h1 {
  text-align: center;
}

.sub-title {
  padding-left: 10px;
  font-size: 1.2rem;
  display: inline-block;
}

.service-items {
  display: flex;
  justify-content: center;
  gap: 15px;
}
</style>

<script>
export default {
  props: {
    assetName: {
      type: String,
      required: true,
    },
    version: String,
  },

  methods: {
    kodUrl() {
      const { assetType, assetId } = this.$route.params;
      const { version } = this.$route.query;
      let url = `/api/curriculum/kods/${assetType}/${assetId}/redirect`;
      if (version) {
        url = `${url}?version=${version}`;
      }
      return url;
    },
  },
};
</script>
