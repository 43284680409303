<template>
  <div v-if="asset">
    <div class="content-with-spacing">
      <div class="heading-container">
        <h1 v-if="asset.name">{{ asset.name }}</h1>
      </div>
      <div class="meeting-container panel panel-default">
        <k-banner>
          <div class="meeting-content">
            <h2 v-if="asset.name">{{ asset.name }}</h2>
            <hr>
            <p class="meeting-description">{{ asset.description }}</p>
            <div class="meeting-details">
              <div class="meeting-dates">
                <h3>When</h3>
                <div>
                  <div class="dates-info start-event">
                    <div v-if="asset.meeting_start && asset.meeting_end">
                      From: <b>{{ parseTimestamp(asset.meeting_start, undefined, "do MMM yyyy HH:mm") }}</b>
                      <small>({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</small>
                      <br>
                      Until: <b>{{ parseTimestamp(asset.meeting_end, undefined, "do MMM yyyy HH:mm") }}</b>
                      <small>({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</small>
                    </div>
                    <div v-else-if="asset.meeting_start">
                      At: <b>{{ parseTimestamp(asset.meeting_start, undefined, "do MMM yyyy HH:mm") }}</b>
                      <small>({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</small>
                    </div>
                    <div v-else>
                      <i>To be confirmed</i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="meeting-links">
                <h3>Where</h3>
                <div v-if="asset.meeting_link">
                  <div class="link-info">
                    <span>Link:</span>
                    <a :href="asset.meeting_link"
                      @click="$logger.info('Meeting opened', { assetId }, true)"
                      target="_blank">
                      <b>{{ asset.meeting_link }}</b>
                      <i class="fas fa-external-link-alt"></i>
                    </a>
                  </div>
                </div>
                <div v-else class="no-link-av">
                  <div class="link-info">
                    <i>No link currently available</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </k-banner>
      </div>
    </div>
  </div>
</template>

<style scoped>
.heading-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 20px auto;
}

.meeting-content {
  padding: 40px 15px;
  width: 100%;
  gap: 15px;
}

.meeting-content h2 {
  margin-top: 0;
  text-align: center;
}

.meeting-content hr {
  margin: 40px 0;
  background-image: var(--right-gradient);
  border-color: transparent;
  height: 1px;
  opacity: 0.5;
}

.meeting-content > p {
  text-align: center;
  margin-bottom: 40px;
}

.meeting-content h2::first-letter,
.meeting-content > p::first-letter {
  text-transform: capitalize;
}

.meeting-details {
  display: flex;
  justify-content: space-around;
}

.meeting-details h3 {
  margin-bottom: 15px;
  font-family: "apercu_monoregular", monospace;
}

.meeting-dates > div,
.meeting-links > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dates-info b {
  color: var(--kate-type-light);
}

.dates-info small {
  padding-left: 5px;
}

.link-info,
.link-info a {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.link-info a:hover {
  text-decoration: none;
}

.link-info a:hover b {
  text-decoration: underline;
}

.link-info b {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15vw;
}

</style>

<style>
#content .heading-container h1 {
  margin: 0;
}
</style>

<script>
import ErrorMixin from '../mixins/error-mixins';
import TimeMixin from '../mixins/time-mixins';
import KBanner from '../programmes/k-banner.vue';

export default {
  components: {
    KBanner,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    asset: {
      type: Object,
    },
    sidebarReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      now: undefined,
      showModal: false,
      completionType: undefined,
      refresh: undefined,
      confirmation: false,
    };
  },

  beforeMount() {
    this.$Loading.finish();
  },

  mounted() {
    this.now = new Date();
    this.refresh = setInterval(this.updateTime, 1000);
  },

  beforeUnmount() {
    if (this.refresh !== undefined) {
      clearInterval(this.refresh);
    }
  },

  computed: {
    moduleId() {
      return parseInt(this.$route.params.moduleId, 10);
    },
    assetId() {
      return this.asset ? this.asset.id : undefined;
    },
  },
};
</script>
