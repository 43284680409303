<template>
  <div>
    <full-calendar ref="calendar" :options="calendarOptions">
      <template #eventContent="arg">
        <k-calendar-element :arg="arg"></k-calendar-element>
      </template>
    </full-calendar>
    <k-calendar-modal v-if="selectedEvent" :event="selectedEvent" @close="closeModal"></k-calendar-modal>
    <k-calendar-subscription-modal
      v-if="showCalendarSubscriptionModal"
      :icalSubscriptionId="icalSubscriptionId"
      :icalUrl="icalSubscriptionUrl"
      @close="closeCalendarSubscriptionModal"
      @ical-subscription-created="handleSubscriptionCreated"
      @ical-subscription-regenerated="icalSubscriptionUrl = $event.icalUrl"
    >
    </k-calendar-subscription-modal>
  </div>
</template>

<style>
.fc-col-header > thead tr {
  background-color: var(--kate-background-body);
}

/* Headers are <a> tags but don't actually have links so need to override */
.fc-col-header-cell-cushion {
  color: var(--kate-type-light);
  text-decoration: none;
  cursor: default;
}

.fc-col-header-cell-cushion:hover {
  color: var(--kate-type-light);
  text-decoration: none;
}

.fc .fc-daygrid-day-frame {
  height: 140px;
}

td.fc-day-sun,
td.fc-day-sat {
  background-color: var(--kate-panel-alt);
}

.fc-daygrid-day-number {
  color: var(--kate-type-light);
  font-size: 0.8em;
}

.fc-event-main {
  display: flex;
  flex-direction: column;
}

.fc-event .fc-event-main {
  z-index: unset;
}

.fc-h-event {
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.fc-h-event.fc-event-main {
  color: var(--kate-type-dark);
}

.fc-event-past.fc-daygrid-dot-event > div:first-child .k-event,
.fc-event-past.fc-h-event .fc-event-main > div:first-child .k-event {
  opacity: 0.5;
}

.fc-event.meeting:hover {
  background-color: var(--kate-logo-magenta);
  border-color: var(--kate-logo-magenta);
}

.fc-event.calendar_event:hover {
  background-color: var(--kate-type-blue-dark);
  border-color: var(--kate-type-blue-dark);
}

.fc-event.module_completion:hover {
  background-color: var(--kate-bronze);
  border-color: var(--kate-bronze);
}

.fc-event.module_start:hover {
  background-color: var(--kate-success-dark);
  border-color: var(--kate-success-dark);
}

a.fc-event:hover .dot-event .dot-event-time {
  color: var(--kate-type-white);
}

.fc-daygrid-dot-event:hover {
  background: var(--kate-background-body-alpha);
}

.fc-event-main > a,
.fc-h-event:hover > a {
  color: var(--kate-type-light);
}

.fc-event.module_completion {
  color: var(--kate-warning);
}

.fc-event.module_start {
  color: var(--kate-success-alt);
}

.fc .fc-button-primary {
  background-color: var(--kate-button-primary);
  border-color: var(--kate-button-primary);
  color: var(--kate-type-dark);
}

.fc .fc-button-primary:disabled {
  background-color: var(--kate-button-tertiary);
  border-color: var(--kate-button-tertiary);
}

.fc .fc-button-primary:disabled:hover {
  background-color: var(--kate-button-tertiary);
  border-color: var(--kate-button-tertiary);
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:focus {
  background-color: var(--kate-button-primary-hover);
  border-color: var(--kate-button-primary-hover);
}

.fc .fc-button-primary:active {
  background-color: var(--kate-button-primary);
}

.fc .fc-daygrid-day-number {
  z-index: 0;
}

.fc .fc-daygrid-event {
  z-index: unset;
}

.fc .fc-button-group {
  gap: 2px;
}

/* Dot event */
.fc-daygrid-dot-event {
  overflow: hidden;
}

.fc-h-event.meeting .dot-event i.fas.fa-circle {
  color: var(--kate-logo-magenta) !important;
}

.fc-timegrid-event.meeting {
  background-color: var(--kate-logo-magenta);
  border: 1px solid var(--kate-logo-magenta);
}

.fc-timegrid-event.module_completion {
  background-color: var(--kate-warning);
  border: 1px solid var(--kate-warning);
}

.fc-timegrid-event.module_start {
  background-color: var(--kate-success-dark);
  border: 1px solid var(--kate-success-dark);
}

.fc-daygrid-dot-event.meeting .dot-event i {
  color: var(--kate-logo-magenta);
}

.fc-daygrid-dot-event.calendar_event .dot-event i {
  color: var(--kate-button-primary);
}

.fc-daygrid-dot-event.meeting .dot-event,
.fc-daygrid-dot-event.calendar_event .dot-event {
  color: var(--kate-type-light);
}

.fc-timegrid-event .dot-event .dot-event-time {
  color: var(--kate-type-white);
}

/* Remove dot icon when on week/day view */
.fc-timegrid-event .dot-event span i {
  display: none;
}

/* Timegrid events */
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  border: 1px solid var(--kate-mono-0);
  box-shadow: var(--box-shadow);
}

a.fc-timegrid-event.calendar_event:hover {
  background-color: var(--kate-type-blue-dark);
}

a.fc-timegrid-event.meeting:hover {
  background-color: var(--kate-meeting-hover);
}

/* Event details modal */
.calendar-modal {
  cursor: default;
}

.calendar-modal .modal-header::before {
  display: none;
}

.calendar-content .modal-container {
  min-width: 400px;
  box-shadow: var(--box-shadow);
}

.calendar-modal .modal-header {
  margin-top: 25px;
  padding: 25px;
  border-bottom: 0;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: baseline;
}

.calendar-modal .modal-body {
  display: none;
}

.calendar-modal .modal-footer {
  border-top: 2px solid var(--kate-background-alt-alpha);
  padding: 8px 15px;
}

/* Popover */
.calendar-content .fc-popover {
  border: 2px solid var(--kate-background-body);
  box-shadow: var(--box-shadow);
}

.calendar-content .fc-popover-header {
  background-color: var(--kate-background-body);
  color: var(--kate-type-light);
}

.fc-popover-body {
  background-color: var(--input-background);
}

.fc-popover-body .fc-daygrid-event-harness {
  margin: 5px 0;
}

/* Week view grid */
.fc .fc-timegrid .fc-daygrid-body {
  z-index: 1;
}

.fc .fc-timegrid-slot-minor {
  border-top-color: var(--kate-background-alt);
}

/* Don't want a box shadow clicking the buttons */
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
</style>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import KCalendarElement from './k-calendar-element.vue';
import KCalendarModal from './k-calendar-modal.vue';
import KCalendarSubscriptionModal from './k-calendar-subscription-modal.vue';

export default {
  components: {
    FullCalendar,
    KCalendarElement,
    KCalendarModal,
    KCalendarSubscriptionModal,
  },

  props: {
    schedule: Array,
    existingIcalSubscriptionUrl: String,
    existingIcalSubscriptionId: Number,
  },

  data() {
    return {
      selectedEventId: null,
      showCalendarSubscriptionModal: false,
      icalSubscriptionUrl: this.existingIcalSubscriptionUrl,
      icalSubscriptionId: this.existingIcalSubscriptionId,
    };
  },

  computed: {
    selectedEvent() {
      return this.schedule.find(x => x.id === this.selectedEventId);
    },

    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        customButtons: {
          icalSubscriptionButton: {
            text: 'Subscribe to Calendar',
            hint: 'Subscribe to this calendar so that you can see it in your own calendar app.',
            click: () => {
              if (this.schedule.length > 0) {
                this.openCalendarSubscriptionModal();
              } else {
                this.$ktoast.warning('No schedule found for this programme');
              }
            },
          },
        },
        headerToolbar: {
          left: 'prev,next icalSubscriptionButton',
          center: 'title',
          right: 'today dayGridMonth,timeGridWeek,timeGridDay', // user can switch between them
        },
        events: this.schedule,
        contentHeight: 'auto',
        dayMaxEvents: true,
        nowIndicator: true,
        views: {
          dayGrid: {
            dayMaxEvents: 3,
            displayEventTime: true,
            displayEventEnd: false,
          },
          timeGrid: {
            allDaySlot: false,
          },
        },
        eventClick: this.toggleDetails,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
        },
      };
    },
  },

  methods: {
    toggleDetails(ev) {
      if (this.selectedEventId === ev.event.id) {
        this.selectedEventId = null;
      } else {
        this.selectedEventId = ev.event.id;
      }
    },

    closeModal() {
      this.selectedEventId = null;
    },

    openCalendarSubscriptionModal() {
      this.showCalendarSubscriptionModal = true;
    },

    closeCalendarSubscriptionModal() {
      this.showCalendarSubscriptionModal = false;
    },

    handleSubscriptionCreated(event) {
      this.icalSubscriptionId = event.icalSubscriptionId;
      this.icalSubscriptionUrl = event.icalUrl;
    },
  },
};
</script>
