<template>
  <li :class="{'completed': markdownPage.is_complete}">
    <i class="fa-duotone fa-file-lines page-icon k-asset-icon"></i>
    <router-link :to="{ name: 'module_markdown_page', params: { moduleId: moduleId, moduleMarkdownPageId: markdownPage.id }}">
      {{ markdownPage.name }}
    </router-link>
    <i v-if="markdownPage.is_complete" class="fas fa-check"></i>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_markdown_page', params: { moduleId: moduleId, moduleMarkdownPageId: markdownPage.id }}">
        View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    markdownPage: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
  },
};
</script>
