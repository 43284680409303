<template>
  <div class="buttons-container sticky content-with-spacing">
    <router-link v-if="prevAsset" :to="prevAsset.route" class="prev-btn" title="Previous asset" aria-label="Previous asset"><i class="fas fa-long-arrow-alt-left"></i> {{prevAsset.name}}</router-link>
    <hr>
    <router-link v-if="nextAsset" :to="nextAsset.route" class="next-btn" title="Next asset" aria-label="Next asset">{{nextAsset.name}} <i class="fas fa-long-arrow-alt-right"></i></router-link>
  </div>
</template>

<style scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  font-size: 0.85em;
  position: sticky;
  top: 70px;
  padding: 15px 0;
  z-index: 99;
  background-color: var(--kate-background-body);
}

.buttons-container hr {
  margin: 0;
  border: 1px solid var(--kate-background-alt-alpha);
  flex: 1 1;
}

.buttons-container a {
  border: 1px solid var(--kate-type-link);
  padding: 5px 10px;
  transition: all 0.3s;
}

.buttons-container a:hover {
  border: 1px solid var(--kate-secondary);
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .buttons-container a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons-container a.next-btn {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
</style>

<script>

export default {

  props: {
    nextAsset: {
      type: Object,
    },
    prevAsset: {
      type: Object,
    },
  },
};
</script>
