<template>
  <li :class="{'completed': slides.is_complete}">
    <i class="k-asset-icon" :class="getAssetIcon('slide_deck')"></i>
    <router-link :to="{ name: 'module_slides', params: { moduleId: moduleId, moduleSlidesId: slides.id }}">
      {{ slides.name }}
    </router-link>
    <i v-if="slides.is_complete" class="fas fa-check"></i>
    <router-link class="view-content btn btn-outlined" :to="{ name: 'module_slides', params: { moduleId: moduleId, moduleSlidesId: slides.id }}">
        View content <i class="fas fa-long-arrow-alt-right"></i>
    </router-link>
  </li>
</template>

<script>
import AssetIconMixin from '../mixins/asset-icon-mixin';

export default {
  mixins: [AssetIconMixin],
  props: {
    slides: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
  },
};
</script>
