<template>
    <div class="panel panel-horizontal module-card-container flex-this">
      <div class="numbering-col">
        <template v-if="showNumber">
          <span v-if="!progMod.number">-</span>
          <span v-else>{{progMod.number}}</span>
        </template>
      </div>
      <div class="panel-body">
        <div class="module-content">
          <div class="module-header">
            <h2 class="module-title">{{ progMod.name }}</h2>
            <div class="completion-date" v-if="progMod.expected_completion_date && layout === 'list-view'">
              <span>Due: <b>{{ parseTimestamp(progMod.expected_completion_date)}}, ({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</b></span>
            </div>
          </div>
          <div class="module-progress-container">
            <div class="completion-date" v-if="progMod.expected_completion_date && layout === 'grid-view'">
              <span>Due: <b>{{ parseTimestamp(progMod.expected_completion_date)}}, ({{ Intl.DateTimeFormat().resolvedOptions().timeZone}})</b></span>
            </div>
            <k-progress
              shape="bar"
              :percentage="progMod.percentage"
              :precision="1"
              :show-bar-text="true"
              size="small"
              customText="Completed"
              ariaLabel="Overall Module Completion"
            ></k-progress>
          </div>
          <div class="row module-body">
            <div v-if="moduleDescription" v-html="moduleDescription" class="row module-description"></div>
            <div class="module-button-container">
              <router-link class="btn btn-module btn-primary" :to="modulePageTo">
                View Module <i class="fas fa-arrow-circle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
/* Module Card */
.panel-horizontal .panel-body {
  position: relative;
  width: 100%;
}

.panel-horizontal .module-content,
.panel-horizontal .module-body {
  display: flex;
  justify-content: space-between;
}

.panel-horizontal .module-content {
  height: 100%;
  flex-direction: column;
}

.panel-horizontal .module-body {
  gap: 15px;
}

.left-element {
  display: flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.left-element > i {
  font-size: 6.5em;
  color: var(--kate-primary);
}

.btn-module {
  width: 200px;
}

/* Module */
.module-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

.module-card-container.flex-this {
  padding-left: 0;
  padding-right: 0;
}

.module-details {
  padding: 0 0 5px;
}

.module-details span {
  font-weight: bold;
  color: var(--kate-secondary);
  font-size: 1.1em;
}

.module-title {
  color: var(--kate-type-light);
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

.module-progress-container {
  margin-bottom: 20px;
}

.panel.panel-horizontal .project-description ul li {
  word-break: break-word;
  hyphens: auto;
  color: var(--kate-type-primary);
}

.panel-horizontal .btn-block {
  box-shadow: var(--box-shadow);
}

.module-body {
  padding: 15px 0 10px;
}

.numbering-col {
  display: flex;
  align-items: center;
  background-color: rgb(92 255 243 / 0.5);
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 60px;
}

.numbering-col span {
  color: var(--kate-mono-0);
  font-size: 2.5em;
  font-weight: bold;
  font-family: "apercu_monoregular", monospace;
}

@media (max-width: 768px) {
  .module-card-container.panel.panel-horizontal {
    min-height: unset;
    height: auto;
    width: 100%;
  }

  .right-element {
    display: block;
    height: unset;
    padding: 15px 0;
  }

  .module-card-container.panel {
    display: inline-table;
  }

  .numbering-col {
    justify-content: center;
    border-radius: 15px 15px 0 0;
    height: auto;
    width: 100%;
  }

  .numbering-col span {
    font-size: 2em;
  }

  .panel-horizontal .panel-body {
    min-height: unset;
  }

  .panel-horizontal .module-content {
    text-align: center;
    margin-bottom: 0;
  }

  .panel-horizontal .module-header,
  .panel-horizontal .module-body {
    flex-direction: column;
  }

  .module-description {
    text-align: left;
  }
}
</style>

<script>
import TimeMixin from '../mixins/time-mixins';
import ModuleMixin from '../mixins/module-mixin';
import KProgress from '../components/k-progress.vue';

export default {
  components: {
    KProgress,
  },

  mixins: [TimeMixin, ModuleMixin],

  props: {
    progMod: {
      type: Object,
      required: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
    },
  },

  computed: {
    imagePath() {
      if (this.progMod) {
        return this.getImagePath(this.progMod.icon);
      }
      return undefined;
    },

    modulePageTo() {
      return {
        name: 'module_landing',
        params: { moduleId: this.progMod.id },
      };
    },

    moduleDescription() {
      return this.getTruncatedModuleDescription(this.progMod);
    },
  },
};
</script>
